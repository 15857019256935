import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setAccessToken } from "../../utils";
import axiosInstance from "../../api/axiosInstance";
import "./otp-verification.css"; // Assuming you create the CSS similar to login.css

const OTPVerification = () => {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const email = location.state?.email; // Get the email passed from login
  // console.log("email", email);

  // Function to handle OTP submission
  const handleVerifyOTP = async (e) => {
    e.preventDefault();

    try {
      // Call the API to verify the OTP
      const response = await axiosInstance.post(
        `/auth/clinic_verification/`,
        {
          clinic_email: email,
          code: otp,
        }
      );
      const Data = response.data;
      if (Data.status) {
        setAccessToken(Data.token);
        navigate("/ClinicBranch");
      } else {
        setErrorMessage("Invalid OTP, please try again.");
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      setErrorMessage("Something went wrong, please try again.");
    }
  };

  return (
    <div className="otp-container">
      <img className="otp-logo" src="/logo.png" alt="Logo" />
      <div className="otp-form">
        <img
          className="otp-form-logo"
          src="/Virtual Triage.png"
          alt="Virtual Triage Logo"
        />
        <h2 className="otp-h2">OTP Verification</h2>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <form onSubmit={handleVerifyOTP}>
          <div className="otp-form-group">
            <label>Enter OTP</label>
            <input
              type="number"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter the OTP sent to your email"
              required
            />
          </div>
          <button type="submit" className="otp-button">
            Verify OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default OTPVerification;
