import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoader } from "../../context/loaderContext";
import axiosInstance from "../../api/axiosInstance";
import BuySubscription from "../../components/modal/BuySubscription"; // Import Modal component
import "./subscriptions.css"; // Placeholder for CSS

const Subscriptions = () => {
  const { showLoader, hideLoader } = useLoader();
  const [currentSubscription, setCurrentSubscription] = useState([]);
  const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const getPaymentMethods = () => {
      showLoader();
      axiosInstance
        .get("/billing/add_billing/")
        .then((resp) => {
          const Data = resp.data;
          if (Data.status) {
            setPaymentMethods(Data.data);
          } else {
            toast.warn(Data.message);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          hideLoader();
        });
    };

    fetchSubscriptions("/subscription/clinic_subscription/", setCurrentSubscription);
    fetchSubscriptions("/subscription/clinic_other_subscription/", setAvailableSubscriptions);
    getPaymentMethods();
  }, []);

  const fetchSubscriptions = (url, successCallback) => {
    showLoader();
    axiosInstance
      .get(url)
      .then((response) => {
        const Data = response.data;
        if (Data.status) {
          successCallback(Data.data);
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleOpen = (id) => {
    setSelectedPackageId(id);
    setShowModal(true); // Show the modal when a subscription is selected
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  const handleSubscribe = ({ charge_token }) => {
    showLoader();
    const postData = {
      package_id: selectedPackageId,
      charge_token
    }
    axiosInstance
      .post('/subscription/clinic_subscription/', postData)
      .then((response) => {
        const Data = response.data;
        if (Data.status) {
          fetchSubscriptions("/subscription/clinic_subscription/", setCurrentSubscription);
          toast.success(Data.message);
          setShowModal(false); 
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div className="subscriptions">
      <h2>My Subscription</h2>

      {/* Current Subscription Details */}
      <h3>Current Subscription</h3>
      {currentSubscription?.length > 0 ? (
        currentSubscription.map((pkg) => (
          <div key={pkg.PackId} className="current-subscription">
            <div className="subscription-detail">
              <label>Subscription Name:</label>
              <input type="text" value={pkg?.package} disabled />
            </div>
            <div className="subscription-detail">
              <label>Start Date:</label>
              <input type="date" value={pkg?.startdate} disabled />
            </div>
            <div className="subscription-detail">
              <label>End Date:</label>
              <input type="date" value={pkg?.enddate} disabled />
            </div>
            <div className="subscription-detail">
              <label>Amount:</label>
              <input type="text" value={pkg?.amount} disabled />
            </div>
          </div>
        ))
      ) : (
        <h3>No Subscription Found</h3>
      )}

      {/* Available Packages */}
      <h3>Other Subscriptions</h3>
      <div className="available-packages">
        {availableSubscriptions.map((pkg) => (
          <div key={pkg.PackId} className="package-card">
            <div className="subscription-detail">
              <label>Subscription Name:</label>
              <input type="text" value={pkg.PackName} disabled />
            </div>
            <div className="subscription-detail">
              <label>Duration:</label>
              <input type="text" value={pkg.PackDurationEnd} disabled />
            </div>
            <div className="subscription-detail">
              <label>Amount:</label>
              <input type="text" value={pkg.PackPrice} disabled />
            </div>
            <button onClick={() => handleOpen(pkg?.PackId)}>
              Buy Subscription
            </button>
          </div>
        ))}
      </div>

      {/* Modal for Payment Selection */}
      {showModal && (
        <BuySubscription paymentMethods={paymentMethods} handleSubscribe={handleSubscribe} onClose={handleCloseModal}>
          <h2>Select Payment Method</h2>
          <p>Choose your existing payment method or add a new one.</p>
          {/* Add components or UI elements for selecting/adding payment methods here */}
        </BuySubscription>
      )}
    </div>
  );
};

export default Subscriptions;
