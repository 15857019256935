import React, { useState, useEffect } from "react";
import AddClinicBranchModal from "./AddClinicBranchModal";
import { Button } from "react-bootstrap";
import "./ClinicBranches.css";
import { useLoader } from "../../context/loaderContext";
import axiosInstance from "../../api/axiosInstance";
import { toast } from "react-toastify";
// import Joyride from "react-joyride";

const ClinicBranches = () => {
  const { showLoader, hideLoader } = useLoader();
  const [modalShow, setModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [clinicToDelete, setClinicToDelete] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [clinics, setClinics] = useState([]);
  // const [tourRunning, setTourRunning] = useState(true);

  // const steps = [
  //   {
  //     target: ".add-clinic-btn",
  //     content: "Click here to add a new clinic branch.",
  //   },
  //   {
  //     target: ".clinics-table",
  //     content: "Here is the list of all clinic branches.",
  //   },
  //   {
  //     target: ".edit-btn",
  //     content: "Click this button to edit the clinic details.",
  //   },
  //   {
  //     target: ".delete-btn",
  //     content: "Click this button to delete the clinic.",
  //   },
  // ];

  useEffect(() => {
    getClinics();
  }, [modalShow]);

  const getClinics = () => {
    showLoader();
    axiosInstance
      .get("clinic/clinic_hospital/")
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setClinics(Data.data);
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleDeleteConfirm = (clinic, e) => {
    e.stopPropagation();
    setClinicToDelete(clinic);
    setShowDeleteConfirm(true);
  };

  const confirmDeleteClinic = () => {
    showLoader();
    const data = {
      Hospital_id: clinicToDelete.Hospital_id,
    };
    axiosInstance
      .delete("clinic/clinic_hospital/", { data })
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          getClinics();
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setShowDeleteConfirm(false);
        setClinicToDelete(null);
      });
  };

  const closeDeleteConfirm = () => {
    setShowDeleteConfirm(false);
    setClinicToDelete(null);
  };

  const handleEditClinic = (clinic, e) => {
    e.stopPropagation();
    setIsEdit(true);
    setSelectedClinic(clinic);
    setModalShow(true);
  };

  const handleRowClick = (clinic) => {
    setSelectedClinic(clinic);
    setShowProfileModal(true);
  };

  const closeProfileModal = () => {
    setShowProfileModal(false);
    setSelectedClinic(null);
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* <Joyride
        steps={steps}
        continuous
        showProgress
        showSkipButton
        run={tourRunning}
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
        callback={(data) => {
          const {status} = data;
          if (status === "finished" || status === "skipped"){
            setTourRunning(false);
          }
        }}
      /> */}
      <h1>Clinic Branches</h1>
      <Button
        className="add-clinic-btn"
        variant="primary"
        onClick={() => {
          setSelectedClinic(null);
          setModalShow(true);
        }}
      >
        Add New Clinic Branch
      </Button>
      {/* <Button
        variant="secondary"
        onClick={() => setTourRunning(true)}
        style={{ marginLeft: "10px" }}
      >
        Start Tour
      </Button> */}

      {/* Modal to add clinic branch */}
      <AddClinicBranchModal
        selectedClinic={selectedClinic}
        show={modalShow}
        isEdit={isEdit}
        handleClose={() => {
          setSelectedClinic(null);
          setIsEdit(false);
          setModalShow(false);
        }}
      />

      {/* List of clinics */}
      <div className="patients-list">
        {clinics.length === 0 ? (
          <p>No clinic branches added yet.</p>
        ) : (
          <table className="clinics-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Specialty</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {clinics.map((clinic) => (
                <tr
                  key={clinic.Hospital_id}
                  onClick={() => handleRowClick(clinic)}
                >
                  <td>{clinic.Hospital_Name}</td>
                  <td>{clinic.Hospital_Speciality}</td>
                  <td>
                    <button
                      className="edit-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClinic(clinic, e);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="delete-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConfirm(clinic, e);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="modal-overlay" onClick={closeDeleteConfirm}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>
              Are you sure you want to delete {clinicToDelete?.Hospital_Name}?
            </h3>
            <button onClick={confirmDeleteClinic}>Yes, Delete</button>
            <button onClick={closeDeleteConfirm}>Cancel</button>
          </div>
        </div>
      )}

      {/* Profile View Modal */}
      {showProfileModal && selectedClinic && (
        <div className="modal-overlay" onClick={closeProfileModal}>
          <div
            className="modal-content text-align-start"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Clinic Profile</h3>
            <img
              src={selectedClinic.Hospital_Image || "./profile.jfif"}
              alt="Profile"
              className="profile-picture-large"
            />
            <p>
              <strong>Name:</strong> {selectedClinic.Hospital_Name}
            </p>
            <p>
              <strong>Speciality: </strong> {selectedClinic.Hospital_Speciality}
            </p>
            <p>
              <strong>About: </strong> {selectedClinic.About}
            </p>
            <p>
              <strong>Bio:</strong> {selectedClinic.Bio}
            </p>
            <button onClick={closeProfileModal} style={{ marginTop: "10px" }}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClinicBranches;
