import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../context/loaderContext";
import axiosInstance from "../../api/axiosInstance";
import { convertDate, convertDate2 } from "../../utils";
import "./appointments.css";
import { rescheduleAppointment } from "../../Redux/features/appointmentSlice";
import Modal from "../../components/modal/calendarModal";

// const sampleAppointments = [
//     {
//         id: 1,
//         patientName: "Alice Brown",
//         practitionerName: "Dr. John Doe",
//         appointmentDate: "2023-09-20",
//         appointmentTime: "10:00 AM - 10:30 AM",
//         appointmentStatus: "Comfirmed",
//         patientForms: {
//             filledByPatient: ["Form A", "Form B"],
//             filledByPractitioner: ["Form C"]
//         }
//     },
//     {
//         id: 2,
//         patientName: "Bob Green",
//         practitionerName: "Dr. Sarah Parker",
//         appointmentDate: "2023-09-21",
//         appointmentTime: "11:00 AM - 11:30 AM",
//         appointmentStatus: "Pending",
//         patientForms: {
//             filledByPatient: ["Form D"],
//             filledByPractitioner: []
//         }
//     },
// ];

const AllAppointments = () => {
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useLoader();
  const { loading } = useSelector((state) => state.appointment);
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] =
    useState(appointments);
  const [selectedAppointment, setSelectedAppointment] = useState(null); // For appointment details view
  const [isReschedule, setIsReschedule] = useState(false); // For appointment details view
  const [selectedFilterType, setSelectedFilterType] = useState(""); // Dropdown selection (Patient, Practitioner, ID)
  const [filterValue, setFilterValue] = useState(""); // Value for the input field
  const [filters, setFilters] = useState({
    dateRange: { startDate: "", endDate: "" },
    appointmentStatus: "All",
  });

  const selectedAppointmentModal = {
    id: selectedAppointment?.Book_Appointment_id,
    title: `${selectedAppointment?.Patient_id} - ${selectedAppointment?.Health_Professional}`,
    start: new Date(
      `${selectedAppointment?.Date.split("-").reverse().join("-")} ${
        selectedAppointment?.Time.split(" to ")[0]
      }`
    ),
    end: new Date(
      `${selectedAppointment?.Date.split("-").reverse().join("-")} ${
        selectedAppointment?.Time.split(" to ")[1]
      }`
    ),
    patientName: selectedAppointment?.Patient_id,
    practitionerId: selectedAppointment?.Health_Professional_id,
    practitionerName: selectedAppointment?.Health_Professional,
    status: selectedAppointment?.Status,
    owedAmount: "$200",
    patientImage: selectedAppointment?.PatientImage,
    practitionerImage: selectedAppointment?.Health_Professional_image,
  };

  useEffect(() => {
    if (loading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [loading]);

  useEffect(() => {
    setFilteredAppointments(appointments);
  }, [appointments]);

  useEffect(() => {
    getAppointments();
  }, []);

  const getAppointments = () => {
    showLoader();
    axiosInstance
      .get(
        `/appointment/clinic_appoitment/?status=${
          filters.appointmentStatus
        }&start_date=${convertDate2(
          filters.dateRange.startDate
        )}&end_date=${convertDate2(filters.dateRange.endDate
        )}&doctor_name=${filterValue}`
      )
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setAppointments(Data.data);
          setFilters({
            ...filters,
            dateRange: {
              startDate: convertDate(Data.start_date),
              endDate: convertDate(Data.end_date),
            },
          });
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const cancelAppoinment = () => {
    showLoader();
    axiosInstance
      .delete(`/appointment/clinic_appoitment/`, {
        data: { appointment_id: selectedAppointment.Book_Appointment_id },
      })
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          toast.success(Data.message);
          setAppointments(
            appointments.filter(
              (a) =>
                a.Book_Appointment_id !==
                selectedAppointment.Book_Appointment_id
            )
          );
          closeModal();
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Handle filter input field changes
  const handleFilterValueChange = (e) => {
    setFilterValue(e.target.value);
  };

  // Filter appointments based on the selected filter type and input value
  const applyFilters = () => {
    // let filtered = appointments;

    // if (selectedFilterType === "patient") {
    //   filtered = filtered.filter((appointment) =>
    //     appointment.Patient_id.toLowerCase().includes(filterValue.toLowerCase())
    //   );
    // }

    // if (selectedFilterType === "practitioner") {
    //   filtered = filtered.filter((appointment) =>
    //     appointment.practitionerName
    //       .toLowerCase()
    //       .includes(filterValue.toLowerCase())
    //   );
    // }

    // if (selectedFilterType === "appointmentId") {
    //   filtered = filtered.filter((appointment) =>
    //     appointment.Book_Appointment_id.includes(filterValue)
    //   );
    // }

    // if (filters.dateRange.startDate && filters.dateRange.endDate) {
    //   filtered = filtered.filter((appointment) => {
    //     const appointmentDate = new Date(appointment.Date);
    //     return (
    //       appointmentDate >= new Date(filters.dateRange.startDate) &&
    //       appointmentDate <= new Date(filters.dateRange.endDate)
    //     );
    //   });
    // }
    // setFilteredAppointments(filtered);
    getAppointments();
  };

  // Handle clicking on an appointment to view details
  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
  };

  // Close the appointment details modal
  const closeModal = () => {
    setIsReschedule(false);
    setSelectedAppointment(null);
  };

  const handleReschedule = (date, slot) => {
    dispatch(
      rescheduleAppointment({
        id: selectedAppointment.Book_Appointment_id,
        date,
        slot,
      })
    )
      .unwrap() // To handle promises if needed
      .then(() => {
        getAppointments(); // After success, get appointments again
        closeModal();
      })
      .catch((error) => {
        toast.error(error); // Handle errors if needed
      });
  };

  // View forms filled by patient
  // const viewPatientForms = () => {
  //   alert(
  //     `Forms filled by patient: ${selectedAppointment.patientForms.filledByPatient.join(
  //       ", "
  //     )}`
  //   );
  // };

  // View forms filled by practitioner
  // const viewPractitionerForms = () => {
  //   alert(
  //     `Forms filled by practitioner: ${selectedAppointment.patientForms.filledByPractitioner.join(
  //       ", "
  //     )}`
  //   );
  // };

  return (
    <div className="appointments-all">
      <h2>All Appointments</h2>

      {/* Search and Filter Options */}
      <div className="appointments-filters">
        {/* Dropdown to select filter type */}
        {/* <select
          name="filterType"
          value={selectedFilterType}
          onChange={(e) => setSelectedFilterType(e.target.value)}
        >
          <option value="">Select Filter Type</option>
          <option value="patient">Patient Name</option>
          <option value="practitioner">Practitioner Name</option>
          <option value="appointmentId">Appointment ID</option>
        </select> */}

        {/* Dynamic input field based on the selected filter type */}
        {/* {selectedFilterType && ( */}
          <input
            type="text"
            name="filterValue"
            placeholder={`Search by Practitioner Name`}
            value={filterValue}
            onChange={handleFilterValueChange}
          />
        {/* )} */}

        <div>
          <label>Date Range: </label>
          <input
            type="date"
            name="startDate"
            value={filters.dateRange.startDate}
            onChange={(e) =>
              setFilters({
                ...filters,
                dateRange: { ...filters.dateRange, startDate: e.target.value },
              })
            }
          />
          <input
            type="date"
            name="endDate"
            value={filters.dateRange.endDate}
            onChange={(e) =>
              setFilters({
                ...filters,
                dateRange: { ...filters.dateRange, endDate: e.target.value },
              })
            }
          />
        </div>

        <select
          name="appointmentStatus"
          value={filters.appointmentStatus}
          onChange={(e) =>
            setFilters({ ...filters, appointmentStatus: e.target.value })
          }
        >
          <option value="">Filter by Status</option>
          <option value="All">All Appointments</option>
          <option value="Pending">Pending</option>
          <option value="Accept">Accepted</option>
          <option value="Cancelled">Cancelled</option>
          {/* <option value="Rejected">Rejected</option> */}
        </select>

        <button onClick={applyFilters}>Apply Filters</button>
      </div>

      {/* List of Appointments */}
      <div className="appointments-list">
        <table>
          <thead>
            <tr>
              <th>Appointment ID</th>
              <th>Patient Name</th>
              <th>Practitioner Name</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredAppointments.map((appointment) => (
              <tr
                key={appointment.Book_Appointment_id}
                onClick={() => handleAppointmentClick(appointment)}
              >
                <td>{appointment.Book_Appointment_id}</td>
                <td>{appointment.Patient_id}</td>
                <td>{appointment.Health_Professional}</td>
                <td>{appointment.Date}</td>
                <td>{appointment.Time}</td>
                <td>{appointment.Accept_Reject_Status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Advanced Appointment Details Modal */}
      {selectedAppointment && (
        <div className="appointments-modal-overlay" onClick={closeModal}>
          <div
            className="appointments-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Appointment Details</h3>

            {/* Overview Section */}
            <div className="appointments-details-overview">
              <p>
                <strong>Appointment ID:</strong>{" "}
                {selectedAppointment.Book_Appointment_id}
              </p>
              <p>
                <strong>Patient Name:</strong> {selectedAppointment.Patient_id}
              </p>
              <p>
                <strong>Practitioner Name:</strong>{" "}
                {selectedAppointment.Health_Professional}
              </p>
              <p>
                <strong>Date:</strong> {selectedAppointment.Date}
              </p>
              <p>
                <strong>Time:</strong> {selectedAppointment.Time}
              </p>
              <p>
                <strong>Status:</strong> {selectedAppointment.Status}
              </p>
            </div>

            {/* Forms Section */}
            <div className="appointments-details-forms">
              <h4>Forms</h4>
              <button>View Forms Filled by Patient</button>
              <button>View Forms Filled by Practitioner</button>
              {/* {selectedAppointment.patientForms.filledByPractitioner.length >
              0 ? (
                <button onClick={viewPractitionerForms}>
                  View Forms Filled by Practitioner
                </button>
              ) : (
                <p>No forms filled by practitioner yet</p>
              )} */}
            </div>

            {/* Options Section */}
            <div className="appointments-details-options">
              <button
                className="reschedule-btn"
                onClick={() => setIsReschedule(true)}
              >
                Reschedule Appointment
              </button>
              <button className="cancel-btn" onClick={cancelAppoinment}>
                Cancel Appointment
              </button>
            </div>

            <button className="close-btn" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}

      {isReschedule && selectedAppointment && (
        <Modal
          appointment={selectedAppointmentModal}
          onClose={() => setIsReschedule(false)}
          onReschedule={handleReschedule}
        />
      )}
    </div>
  );
};

export default AllAppointments;
