// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from "./reducer";

// Create a persist config object
const persistConfig = {
  key: "root",
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create a store with persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types for redux-persist
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        // Optionally ignore paths in the state
        ignoredPaths: ["_persist"],
      },
    }),
});

// Create a persistor object
export const persistor = persistStore(store);
