import {React, useState} from "react";
import "./buy-subscription.css";
import { toast } from "react-toastify";

const BuySubscription = ({ paymentMethods, handleSubscribe, onClose, children }) => {
  console.log('paymentMethods', paymentMethods)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const handleSubs = () => {
    if (!selectedPaymentMethod) {
      toast.info("Please select a payment method!");
      return;
    }
    handleSubscribe({ charge_token: selectedPaymentMethod });
  }

  return (
    <div className="buy-subscription-modal-overlay">
      <div className="buy-subscription-modal-content">
        {children}
        <div className="payment-methods">
          <form>
            <h3>Payment Methods</h3>
            {paymentMethods .length === 0 && <p>No Payment Method Found!</p>}
            {paymentMethods .length === 0 && <small>Please add a payment method to confirm the payment for this purchase.</small>}
            {paymentMethods.map((payment, index) => (
              <div key={payment.id} className="payment-option">
                <label>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value={payment.customerid}
                    checked={selectedPaymentMethod === payment.customerid}
                    onChange={() => setSelectedPaymentMethod(payment.customerid)}
                  />
                  Payment {index+1}
                </label>
              </div>
            ))}
          </form>
        </div>
        <button className="buy-subscription-modal-confirm-btn" onClick={handleSubs} disabled={!paymentMethods.length}>
          Confirm Payment
        </button>
        <a href="billing">
          <button className="buy-subscription-modal-close-btn">Add New Payment Method</button>
        </a>
        <button className="buy-subscription-modal-close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default BuySubscription;
