import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker"; // For date picker
import { customStyles, formatDate } from "../../utils";
import { useLoader } from "../../context/loaderContext";
import { toast } from "react-toastify";
import axiosInstance from "../../api/axiosInstance";
import { isSameDay, parse } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentDates,
  getAppointmentSchedules,
} from "../../Redux/features/appointmentSlice";

// Define the styled components here
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const Header = styled.h3`
  text-align: center;
  color: #50cada;
  border-bottom: 2px solid #50cada;
  padding-bottom: 15px;
  margin-bottom: 20px;
  margin-top: 0;
`;

// Define FlexContainer, ProfileImage, and InfoContainer styled components
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
`;

const InfoContainer = styled.div`
  flex-grow: 1;
`;

// Existing Button Styles
const ViewButton = styled.button`
  padding: 10px 20px;
  background-color: #50cada;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #3c9aa7;
  }
`;

const PayButton = styled.button`
  padding: 10px 20px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e69500;
  }
`;

const RescheduleButton = styled.button`
  padding: 10px 20px;
  background-color: #f0ad4e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ec971f;
  }
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex-grow: 1; /* Make buttons take equal space */
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ff1a1a;
  }
`;

const DatePickerContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  z-index: 5; // Ensures DatePicker appears on top
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: white;
  color: #333;

  &:focus {
    border-color: #50cada;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__day--selected {
    background-color: #50cada;
    color: white;
  }
`;

const Modal = ({ appointment, onClose, onView, onPay, onReschedule }) => {
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useLoader();
  const { availableDates, slotStartTiming, slotEndTiming, loading, error } =
    useSelector((state) => state.appointment);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState("");
console.log(appointment)
  const {
    patientImage,
    patientName,
    practitionerId,
    practitionerName,
    practitionerImage,
    status,
    owedAmount,
  } = appointment;

  useEffect(() => {
    if (loading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [loading]);

  useEffect(() => {
    if (practitionerId && showRescheduleModal) {
      dispatch(getAppointmentDates(practitionerId));
    }
  }, [practitionerId, showRescheduleModal, getAppointmentDates]);

  useEffect(() => {
    if (selectedDate) {
      dispatch(getAppointmentSchedules({ practitionerId, selectedDate }));
    }
  }, [selectedDate, getAppointmentSchedules]);

  const availableSlotsOptions = slotStartTiming?.map((e, index) => ({
    label: e + " to " + slotEndTiming[index],
    value: e + " to " + slotEndTiming[index],
  }));

  const handleReschedule = () => {
    // Show the reschedule modal
    setShowRescheduleModal(true);
  };

  const handleConfirmReschedule = () => {
    if (selectedDate && selectedSlot) {
      onReschedule(selectedDate, selectedSlot); // Trigger the reschedule action
      setShowRescheduleModal(false); // Close the reschedule modal
    } else {
      toast.warn("Please select a date and time slot.");
    }
  };

  const availableDatesFormat = availableDates?.map((dateStr) =>
    parse(dateStr, "MMM dd yyyy", new Date())
  );

  const isDateAvailable = (date) => {
    return availableDatesFormat.some((availableDate) =>
      isSameDay(date, availableDate)
    );
  };

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <Header>Appointment Details</Header>

          <FlexContainer>
            <ProfileImage
              src={patientImage || "/dummy-profile.png"}
              alt={`${patientName}'s profile`}
            />
            <InfoContainer>
              <p>
                <strong>Patient Name:</strong> {patientName}
              </p>
            </InfoContainer>
          </FlexContainer>

          <FlexContainer>
            <ProfileImage
              src={practitionerImage || "/profile.jfif"}
              alt={`${practitionerName}'s profile`}
            />
            <InfoContainer>
              <p>
                <strong>Practitioner Name:</strong> {practitionerName}
              </p>
            </InfoContainer>
          </FlexContainer>

          <p>
            <strong>Status:</strong> {status}
          </p>
          <p>
            <strong>Owed Amount:</strong> {owedAmount}
          </p>

          <div className="button-container">
            <ViewButton onClick={onView}>View Filled Forms</ViewButton>
            <RescheduleButton onClick={handleReschedule}>
              Reschedule Appointment
            </RescheduleButton>
            <CloseButton onClick={onClose}>Close</CloseButton>
          </div>
        </ModalContent>
      </ModalOverlay>

      {/* Reschedule Modal */}
      {showRescheduleModal && (
        <ModalOverlay onClick={() => setShowRescheduleModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <Header>Reschedule Appointment</Header>

            <div>
              <label>Select New Date:</label>
              <DatePickerContainer>
                <StyledDatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  filterDate={isDateAvailable}
                  placeholderText="Select a date"
                  dateFormat="yyyy-MM-dd"
                />
              </DatePickerContainer>
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Select Time Slot:</label>
              <Select
                styles={customStyles}
                onChange={(selectedOption) => {
                  setSelectedSlot(selectedOption); // Pass selected option to the change handler
                }}
                value={selectedSlot}
                options={availableSlotsOptions}
                placeholder="Select available slot"
                isDisabled={!availableSlotsOptions?.length}
                isClearable
              />
            </div>

            <div className="button-container" style={{ marginTop: "20px" }}>
              <ViewButton onClick={handleConfirmReschedule}>
                Confirm Reschedule
              </ViewButton>
              <CloseButton onClick={() => setShowRescheduleModal(false)}>
                Cancel
              </CloseButton>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default Modal;
