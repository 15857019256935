import React, { useState, useEffect, useRef } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faHeadset } from "@fortawesome/free-solid-svg-icons";
import "./topbar.css";

const Topbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <div className="topbar">
      <div className="help-faq-page">
        <a
          href="https://virtualtriage.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="specific-icon" icon={faCircleQuestion} />
        </a>
        {/* <a> */}
        <FontAwesomeIcon className="specific-icon" icon={faHeadset} />
        {/* </a> */}
      </div>

      <div className="profile-section">
        <div className="profile-icon" onClick={toggleDropdown}>
          <FaUserCircle size={40} />
        </div>
        {dropdownOpen && (
          <div className="dropdown-menu" ref={dropdownRef}>
            <ul>
              <li style={{ padding: 0 }}>
                <Link to="/profile"><li>Profile Settings</li></Link>
              </li>
              <li style={{ padding: 0 }}>
                <Link to="/billing"><li>Billing</li></Link>
              </li>
              <li style={{ padding: 0 }}>
                <Link to="" onClick={logout}><li>Logout</li></Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Topbar;
