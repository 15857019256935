import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLoader } from "../../context/loaderContext";
import "./my-profile.css"; // Placeholder for CSS
import axiosInstance from "../../api/axiosInstance";

const MyProfile = () => {
  const { showLoader, hideLoader } = useLoader();
  // State to store profile data
  const [profile, setProfile] = useState({
    Clinics_BranchName: "",
    Clinic_Owner_Name: "", // You can update this with the correct owner name from the API if available
    Mobile_Number: "",
    Clinics_BranchEmail: "",
    Clinics_BranchAddress: "",
    Registration_Number: "", // Not provided by the API, so leaving as placeholder
    Opening_hours: "", // Not provided by the API, so leaving as placeholder
    Website: "", // Not provided by the API, so leaving as placeholder
    Clinics_BranchId: "",
    Clinic_Image: "",
  });

  const [editing, setEditing] = useState(false);
  const [newLogo, setNewLogo] = useState(null);
  // Fetch data from API when the component mounts

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    showLoader();
    axiosInstance
      .get("/auth/clinic_profile/")
      .then((resp) => {
        const Data = resp.data;
        //console.log(resp);
        if (Data.status) {
          setProfile(Data.data);
        } else {
          // setErrorMessage(resp.message);
          toast.warning(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  // Handler for logo file upload
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewLogo(file);
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   setProfile({ ...profile, Clinic_Image: reader.result });
      // };
      // reader.readAsDataURL(file);
    }
  };

  // Handler to toggle edit mode
  const toggleEdit = () => {
    setEditing(!editing);
  };

  // Handler to save profile details
  const handleSave = () => {
    showLoader();
    const formData = new FormData();
    formData.append("Clinics_BranchName", profile.Clinics_BranchName);
    formData.append("Clinics_BranchAddress", profile.Clinics_BranchAddress);
    formData.append("Clinic_Owner_Name", profile.Clinic_Owner_Name);
    formData.append("Registration_Number", profile.Registration_Number);
    formData.append("Clinics_BranchEmail", profile.Clinics_BranchEmail);
    formData.append("Opening_hours", profile.Opening_hours);
    formData.append("Website", profile.Website);
    if (newLogo) {
      formData.append("Clinic_Image", newLogo);
    }

    // const data = {
    //   Clinics_BranchName: profile.Clinics_BranchName,
    //   Clinics_BranchAddress: profile.Clinics_BranchAddress,
    //   Clinics_BranchCreatedDate: "2023-04-26T14:45:55.301000Z",
    //   Owner_name: profile.Clinic_Owner_Name,
    //   Registration_Number: profile.Registration_Number,
    //   Tax_id: "1234-324-542",
    //   Opening_hours: profile.Opening_hours,
    //   Website: profile.Website,
    //   Clinic_Image: profile.Clinic_Image,
    // };

    axiosInstance
      .put(
        "/auth/clinic_profile/",
        formData
      )
      .then((resp) => {
        const Data = resp.data;
        // console.log(resp);
        if (Data.status) {
          getProfile();
        } else {
          toast.warning(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setEditing(false);
      });
  };

  return (
    <div className="my-profile">
      <h2>My Profile</h2>

      <div className="myprofile-section">
        {editing ? (
          <>
            <div className="profile-item">
              <label>Clinic Name:</label>
              <input
                type="text"
                name="Clinics_BranchName"
                value={profile.Clinics_BranchName}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Owner Name:</label>
              <input
                type="text"
                name="Clinic_Owner_Name"
                value={profile.Clinic_Owner_Name}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Contact Number:</label>
              <input
                type="text"
                name="Mobile_Number"
                value={profile.Mobile_Number}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Email:</label>
              <input
                type="email"
                name="Clinics_BranchEmail"
                value={profile.Clinics_BranchEmail}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Address:</label>
              <textarea
                name="Clinics_BranchAddress"
                value={profile.Clinics_BranchAddress}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Registration Number:</label>
              <input
                type="text"
                name="Registration_Number"
                value={profile.Registration_Number}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Opening Hours:</label>
              <input
                type="text"
                name="Opening_hours"
                value={profile.Opening_hours}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Website:</label>
              <input
                type="text"
                name="Website"
                value={profile.Website}
                onChange={handleChange}
              />
            </div>
            <div className="profile-item">
              <label>Clinic Image:</label>
              <input type="file" accept="image/*" onChange={handleLogoChange} />
              {profile.Clinic_Image && (
                <img
                  src={
                    (newLogo && URL.createObjectURL(newLogo)) ||
                    profile.Clinic_Image
                  }
                  alt="Clinic Logo"
                  className="logo-preview"
                />
              )}
            </div>
            <div className="profile-actions">
              <button onClick={handleSave}>Save</button>
              <button onClick={toggleEdit}>Cancel</button>
            </div>
          </>
        ) : (
          <>
            <div className="profile-item">
              <label>Clinic Name:</label>
              <span>{profile.Clinics_BranchName}</span>
            </div>
            <div className="profile-item">
              <label>Owner Name:</label>
              <span>{profile.Clinic_Owner_Name}</span>
            </div>
            <div className="profile-item">
              <label>Contact Number:</label>
              <span>{profile.Mobile_Number}</span>
            </div>
            <div className="profile-item">
              <label>Email:</label>
              <span>{profile.Clinics_BranchEmail}</span>
            </div>
            <div className="profile-item">
              <label>Address:</label>
              <span>{profile.Clinics_BranchAddress}</span>
            </div>
            <div className="profile-item">
              <label>Registration Number:</label>
              <span>{profile.Registration_Number}</span>
            </div>
            <div className="profile-item">
              <label>Opening Hours:</label>
              <span>{profile.Opening_hours}</span>
            </div>
            <div className="profile-item">
              <label>Website:</label>
              <span>{profile.Website}</span>
            </div>
            {profile.Clinic_Image && (
              <div className="profile-item">
                <label>Clinic Image:</label>
                <img
                  src={profile.Clinic_Image}
                  alt="Clinic Logo"
                  className="clinic-logo"
                />
              </div>
            )}
            <div className="profile-actions">
              <button onClick={toggleEdit}>Edit Profile</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyProfile;
