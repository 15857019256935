export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatDate2 = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
};

export const formatDate3 = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const convertDate = (dateStr) => {
  // Split the input date by dashes
  const [day, month, year] = dateStr.split("-");

  // Return the date in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
};

export const convertDate2 = (dateStr) => {
  if (!dateStr) return "";
  // Split the input date by dashes
  const [year, month, day] = dateStr.split("-");

  // Return the date in YYYY-MM-DD format
  return `${day}-${month}-${year}`;
};

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black", // Text color for selected/unselected
    backgroundColor: state.isSelected ? "#007bff" : "#fff", // Background color
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Background color of the input box
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000", // Text color of the selected option
  }),
};

export const setAccessToken = (token) => {
  localStorage.setItem("token", token);
};

export const getAccessToken = () => {
  return localStorage.getItem("token");
};
