import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faPercentage } from "@fortawesome/free-solid-svg-icons";
import { useLoader } from "../../context/loaderContext";
import { convertDate, convertDate2 } from "../../utils";
import axiosInstance from "../../api/axiosInstance";
import "./revenue.css";

const RevenueGeneration = () => {
  const { showLoader, hideLoader } = useLoader();
  const [Revenue, setRevenue] = useState([]); // Store filtered revenue data
  const [filteredRevenue, setFilteredRevenue] = useState(Revenue); // Store filtered revenue data
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    doctor_name: "",
  });
  const [totalRevenue, setTotalRevenue] = useState(0); // Store total revenue
  const [totalCommission, setTotalCommission] = useState(0); // Store total commission

  //   // Function to calculate total revenue and commission from the data
  const calculateTotals = (data) => {
    const totalRevenueGenerated = data.reduce(
      (total, item) => total + item.total_revenue,
      0
    );
    const totalCommissionGenerated = data.reduce(
      (total, item) => total + item.total_commission,
      0
    );

    setTotalRevenue(totalRevenueGenerated); // Update total revenue
    setTotalCommission(totalCommissionGenerated); // Update total commission
  };

  useEffect(() => {
    if (Revenue.length > 0) return;
    getRevenue();
  }, []);

  useEffect(() => {
    setFilteredRevenue(Revenue);
  }, [Revenue]);

  const getRevenue = () => {
    showLoader();
    axiosInstance
      .get(
        `/revenue_generation/get_revenue_generation/?start_date=${convertDate2(
          filters.startDate
        )}&end_date=${convertDate2(filters.endDate)}&doctor_name=${
          filters.doctor_name
        }`
      )
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setRevenue(Data.data);
          setFilters({
            ...filters,
            startDate: convertDate(Data.start_date),
            endDate: convertDate(Data.end_date),
          });
          calculateTotals(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Handle filter input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  // Apply filters to the revenue data and recalculate totals
  const applyFilters = (e) => {
    e.preventDefault();
    getRevenue();
  };

  // Prepare data for CSV export
  const csvData = filteredRevenue.map((practitioner) => ({
    Practitioner: practitioner.doctor_name,
    Revenue: practitioner.total_revenue,
    Commission: practitioner.total_commission,
  }));

  return (
    <div className="revenue-generation">
      <h2>Revenue Generation</h2>

      {/* Filters Section */}

      <form onSubmit={applyFilters}>
        <div className="revenue-filters">
          <input
            type="date"
            name="startDate"
            value={filters.startDate}
            onChange={handleFilterChange}
          />
          <input
            type="date"
            name="endDate"
            value={filters.endDate}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            name="doctor_name"
            value={filters.doctor_name}
            onChange={handleFilterChange}
            placeholder="Search by Practitioner"
          />
          <button>Apply Filters</button>

          <CSVLink
            data={csvData}
            filename={"revenue-report.csv"}
            className="export-btn"
          >
            Export to Excel
          </CSVLink>
        </div>
      </form>

      {/* Advanced Total Revenue and Commission Card */}
      <div className="totals-container">
        <div className="revenue-section">
          <FontAwesomeIcon className="font-awesomeeicon" icon={faDollarSign} />{" "}
          {/* Revenue Icon */}
          <div className="revenue-text">
            <h3>Total Revenue</h3>
            <p>${totalRevenue}</p>
          </div>
        </div>
        <div className="separator"></div> {/* Vertical Line */}
        <div className="commission-section">
          <FontAwesomeIcon className="font-awesomeeicon" icon={faPercentage} />{" "}
          {/* Commission Icon */}
          <div className="commission-text">
            <h3>Total Commission</h3>
            <p>${totalCommission}</p>
          </div>
        </div>
      </div>

      {/* Revenue Table */}
      <div className="revenue-table">
        <table>
          <thead>
            <tr>
              <th>Practitioner</th>
              <th>Revenue Generated</th>
              <th>Total Commission</th>
              <th>Commission (%)</th>
            </tr>
          </thead>
          <tbody>
            {filteredRevenue.map((practitioner, index) => (
              <tr key={index}>
                <td>{practitioner.doctor_name}</td>
                <td>${practitioner.total_revenue || 0}</td>
                <td>${practitioner.total_commission || 0}</td>
                <td>{practitioner.commission_percentage || 0}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RevenueGeneration;
