import React from "react";
import { ToastContainer } from "react-toastify";
import { LoaderProvider } from "./context/loaderContext";
import AppRoutes from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <LoaderProvider>
      <AppRoutes />
      <ToastContainer />
    </LoaderProvider>
  );
}

export default App;
