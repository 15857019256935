import React from "react";
import { Link, NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
import {
  FaUserMd,
  FaCalendarAlt,
  FaCalendarCheck,
  FaUsers,
  FaDollarSign,
  FaBan,
  FaFileInvoiceDollar,
  FaCog,
  FaSignOutAlt,
  FaHospital,
} from "react-icons/fa"; // Import Font Awesome icons
import "./sidebar.css";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="logo">
        <Link to="/Practitioners">
          <img className="toplogo" src="/logo.png" alt="Logo" />
        </Link>
      </div>
      <nav className="nav-menu">
        <ul>
          <li>
            <NavLink to="/ClinicBranch">
              <FaHospital /> Add Clinic Branch
            </NavLink>
          </li>
          <li>
            <NavLink to="/Practitioners">
              <FaUserMd /> Manage Practitioners
            </NavLink>
          </li>
          <li>
            <NavLink to="/appointments">
              <FaCalendarAlt /> Appointments
            </NavLink>
          </li>
          <li>
            <NavLink to="/calendar">
              <FaCalendarCheck /> Calendar
            </NavLink>
          </li>
          <li>
            <NavLink to="/patients">
              <FaUsers /> Manage Patients
            </NavLink>
          </li>
          <li>
            <NavLink to="/revenue">
              <FaDollarSign /> Revenue Activity
            </NavLink>
          </li>
          <li>
            <NavLink to="/blacklist">
              <FaBan /> Blacklist
            </NavLink>
          </li>
          <li>
            <NavLink to="/subscription">
              <FaFileInvoiceDollar /> Subscription
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile">
              <FaCog /> Profile Settings
            </NavLink>
          </li>
          <li>
            <NavLink to="/logout">
              <FaSignOutAlt /> Logout
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
